export default {
    name: "Moneyness",
    created() {
        this.$nextTick(function () {
 
        });
    },
    mounted() {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
};